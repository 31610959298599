.image-container{
 width: 26px;
 height: 26px;
 position: relative;
}

.image-placeholder{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    bottom: 0;
    border: 1px solid rgb(150, 147, 147);
    background-color: white;
}