* {
  box-sizing: border-box;
}

/* body {
  font-family: "Open Sans", "Lato", sans-serif;
  margin: 0;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

.error-text {
  color: #b40e0e;
  padding-left: 12px;
}

table,
th,
td {
  border: 1px solid lightgray;
  font-size: small;
}
