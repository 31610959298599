.form {
  margin-bottom: 1rem;
}


.h3Text {
  color: #faa62a;
  text-align: center;
  padding-bottom: 10px;
}
.form {
  min-width: 15rem;
  flex: 1;
  justify-content: space-around;
}

/* .form input, */
.form select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 12px;
  border: 1px solid #ccc;
  width: 40rem;
  max-width: 85%;
  margin: 0.5rem;
  margin-left: 35px;
  margin-bottom: 12px;
}

.form {
  width: 95%;
  max-width: 33rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;
  margin: 3rem auto;
  margin-top: 6rem;
}

.form-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1rem;
  outline-width: 0;
  width: 455px;
}

button {
  background: #5d96e0;
  color: #fff;
  width: 60%;
  margin: auto;
  display: block;
  height: 45px;
  border-radius: 12px;
  margin-top: 12px;
  cursor: pointer;
}

button :disabled {
  background: #15263d;
  color: black;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100%;
}
