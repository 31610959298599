#recent-events-scroll-container::-webkit-scrollbar {
  width: 6px;
}
  
#recent-events-scroll-container::-webkit-scrollbar-track {
  background-color: #d8d7d7;
  width: 3px;
  border-radius: 100px;
}
  
#recent-events-scroll-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 100px;
  border: 2px solid #d8d7d7;
}

.recentEvents-list{
  cursor: pointer;
  text-align: center;
  list-style: none;
  padding: 10px;
  border-bottom: 1px ridge #dad6d6;
  font-size: 15px;
}
.recentEvents-list-selected{
  cursor: pointer;
  text-align: center;
  list-style: none;
  padding: 10px;
  border-bottom: 1px ridge #dad6d6;
  font-size: 15px;
  background-color: aqua;
}
.recentEvents-list:hover{
  background-color: rgb(182, 220, 238);
}