.bar__chart {
  border-radius: 0;
  width: 74%;
  margin: 20px 0px;
}

.pie__chart {
  width: 25%;
  border-radius: 0;
  margin: 20px 0px;
}

.home_cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bar_chart_content {
  margin: 20px;
}

.pie_chart_content {
  margin: 60px 0 0 0;
}

.chart_title_text {
  font-size: " 22px";
  color: rgb(20, 20, 20);
  font-weight: 500;
}

.home_admin_tables {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.home_user_table {
  width: 49%;
}

.home_event_table {
  width: 50%;
}

.home_table_name {
  margin-left: 10px;
  font-family: sans-serif;
  color: black;
  font-weight: 500;
  font-size: 18px;
}

@media only screen and (max-width: 950px) {
  .home_cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .bar__chart {
    width: 100%;
  }
  .pie__chart {
    width: 50%;
    margin: 0;
  }
  .pie_chart_content {
    margin: 0 0 20px 0;
  }
  .home_admin_tables {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home_user_table {
    width: 100%;
    height: auto;
  }

  .home_event_table {
    width: 100%;
  }
}
