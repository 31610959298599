.home {
  display: flex;
  flex-direction: column;
}

.profile {
  border: 2px solid #ffc409;
  border-radius: 50%;
}

.logo_image {
  margin: 0px 0px;
  padding: 0px 10px 0px 10px;
}
.css-h4y409-MuiList-root{
  padding: 0px !important;
}